import axios from "axios";

const getBaseUrl = (): string => {
    
    if(process.env.REACT_APP_API_URL !== undefined)
        return process.env.REACT_APP_API_URL?.toString();


    // return "http://localhost:5237";
    return "https://api.zeeshop.app";
}

const api = axios.create({
    baseURL: getBaseUrl()
})

export default api;