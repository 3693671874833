import api from "./Api";
import { ResponseHelper } from "../helpers/ResponseHelper";
import { Bairro } from "../models/Bairro";

export class BairroService {

    private endpoint = 'v1/bairros';

    public async obterPorEstabelecimento(id: number): Promise<Bairro[]> {
        return await api.get<Bairro[]>(`${this.endpoint}/estabelecimento/${id}`)
            .then((response) => (ResponseHelper.sucesso(response)))
            .catch((error) => (Promise.reject(ResponseHelper.erro(error))));
    }
}