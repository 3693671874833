import api from "./Api";
import { ResponseHelper } from "../helpers/ResponseHelper";

export class ClienteService {

    private endpoint = 'v1/clientes';

    public async obterPorChave(chave: string): Promise<any> {
        return await api.get<any>(`${this.endpoint}/chave/${chave}`)
            .then((response) => (ResponseHelper.sucesso(response)))
            .catch((error) => (Promise.reject(ResponseHelper.erro(error))));
    }

    public async obterPorTelefone(telefone: string): Promise<any> {
        return await api.get<any>(`${this.endpoint}/telefone/${telefone}`)
            .then((response) => (ResponseHelper.sucesso(response)))
            .catch((error) => (Promise.reject(ResponseHelper.erro(error))));
    }

    public async cadastrar(cliente: any): Promise<any> {
        return await api.post<any>(`${this.endpoint}`, cliente)
            .then((response) => (ResponseHelper.sucesso(response)))
            .catch((error) => (Promise.reject(ResponseHelper.erro(error))));
    }
}