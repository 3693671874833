import * as Yup from "yup";
import { ErrorMessage, Form, FormikProps, withFormik } from 'formik';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { UtilsHelper } from '../../helpers/UtilsHelper';
import { ClienteService } from "../../services/ClienteService";

export interface ICadastroForm 
{
    cliente: any;
    onLogin: any;
}

const innerForm = (props: ICadastroForm & FormikProps<{nome: string, telefone: string}>) => 
{
    const { values } = props;

    const onChangeTelefone = async (event: any) => {
        const { name, value } = event.target;
        let telefoneFormatado = UtilsHelper.formatTelefone(value);
        props.setFieldValue(name, telefoneFormatado);

        if(telefoneFormatado.length === 15)
        {
            let service = new ClienteService();
            let response = await service.obterPorTelefone(telefoneFormatado);

            if(response !== undefined)
            {
                props.setFieldValue(`nome`, response.nome);
            }
        }
    }

    return (
        <Form>
            <div className="grid w-12 m-0">
                <div className="col-12">
                    <div className="label">Seu número de WhatsApp é:</div>
                    <InputText type="tel" name="telefone" className="w-full" value={values.telefone} onChange={onChangeTelefone} />
                    <small className="invalid"><ErrorMessage name="telefone" /></small>
                </div>
                <div className="col-12">
                    <div className="label">Seu nome e sobrenome</div>
                    <InputText name="nome" className="w-full" value={values.nome} onChange={props.handleChange} />
                    <small className="invalid"><ErrorMessage name="nome" /></small>
                </div>
                <div className="col-12">
                    <Button className='p-button-primary w-full button-primary' type="submit" label="Avançar" disabled={ props.isSubmitting } loading={props.isSubmitting} />
                </div>
            </div>
        </Form>
    );
};

const validationForm = Yup.object().shape({
    nome: Yup.string().required("Campo obrigatório"),
    telefone: Yup.string().required("Campo obrigatório")
    .min(15, 'Telefone inválido')
    .test('num', 'Telefone inválido', val => val.toString() !== '(00) 00000-0000')
    .test('num', 'Telefone inválido', val => val.toString() !== '(11) 11111-1111')
    .test('num', 'Telefone inválido', val => val.toString() !== '(22) 22222-2222')
    .test('num', 'Telefone inválido', val => val.toString() !== '(33) 33333-3333')
    .test('num', 'Telefone inválido', val => val.toString() !== '(44) 44444-4444')
    .test('num', 'Telefone inválido', val => val.toString() !== '(55) 55555-5555')
    .test('num', 'Telefone inválido', val => val.toString() !== '(66) 66666-6666')
    .test('num', 'Telefone inválido', val => val.toString() !== '(77) 77777-7777')
    .test('num', 'Telefone inválido', val => val.toString() !== '(88) 88888-8888')
    .test('num', 'Telefone inválido', val => val.toString() !== '(99) 99999-9999')
    .test('num', 'Telefone inválido', val => val.toString() !== '(12) 12345-6789')
    .test('num', 'Telefone inválido', val => val.toString() !== '(12) 34567-8900')
});


const CadastroForm = withFormik<ICadastroForm, {nome: string, telefone: string}>({
    enableReinitialize: true,

    // Mapeamento das propriedades para os campos
    mapPropsToValues: (props) => 
    {
        return props.cliente !== undefined && props.cliente !== null ? props.cliente : {
            nome: '',
            telefone: ''
        };
    },

    validationSchema: validationForm,

    handleSubmit: async (values, { props }) => 
    {
        await props.onLogin(values);
    }    
})(innerForm);

export default CadastroForm;