import React, { useEffect } from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import 'react-toastify/dist/ReactToastify.css';

import './content/css/global.css';
import './content/css/prime.css';
import './content/css/text.css';

import ScrollToTop from './helpers/ScrollToTop';
import ProvideContext from './helpers/ProvideContext';
import HomeContainer from './containers/HomeContainer';
import LojaContainer from './containers/LojaContainer';
import CarrinhoContainer from './containers/CarrinhoContainer';
import ConfirmacaoContainer from './containers/ConfirmacaoContainer';
import IdentificacaoContainer from './containers/IdentificacaoContainer';
import EnderecoContainer from './containers/EnderecoContainer';
import OnboardingContainer from './containers/OnboardingContainer';



function App() {
  useEffect(() => {
		document.title = "Delivery";
	}, []);

  return (
		<ProvideContext>
			<Router>
				<ScrollToTop />
				<Switch>
					<Route exact path="/" component={HomeContainer} />
					<Route exact path="/cardapio-digital" component={OnboardingContainer} />
					<Route exact path="/carrinho" component={CarrinhoContainer} />
					<Route exact path="/confirmar" component={ConfirmacaoContainer} />
					<Route exact path="/identificacao" component={IdentificacaoContainer} />
					<Route exact path="/endereco" component={EnderecoContainer} />
					<Route exact path="/:urlEstabelecimento" component={LojaContainer} />
					<Redirect path="*" to="/" />
				</Switch>
			</Router>
		</ProvideContext>
	);
}

export default App;
