import { Divider } from 'primereact/divider';
import React, { useContext, useEffect, useState } from 'react';
import EnderecoForm from './EnderecoForm';
import { useHistory } from 'react-router-dom';
import { BairroService } from '../../services/BairroService';
import { Bairro } from '../../models/Bairro';
import { Endereco } from '../../models/Endereco';
import GlobalContext from '../../helpers/GlobalContext';
import { EnderecoService } from '../../services/EnderecoService';
import { UtilsHelper } from '../../helpers/UtilsHelper';

const EnderecoComponent: React.FC = () => {

    const history = useHistory();
    const { carrinho, setCarrinho } = useContext(GlobalContext);
    const [bairros, setBairros] = useState<Bairro[]>();

    const carregaBairros = async () => {

        try {

            if (carrinho.estabelecimento === undefined){
                let dadosCarrinho = UtilsHelper.getLocalStorage('cart.delivery');
                setCarrinho({...dadosCarrinho});
            }

            let service = new BairroService();
            let response = await service.obterPorEstabelecimento(1);

            if (response !== undefined)
                setBairros(response);
        }
        catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        carregaBairros();
    }, [])
    
    const salvar = async (endereco: Endereco) => {
        try {
        

            endereco.cliente = {id: carrinho.cliente.id}

            let service = new EnderecoService()
            let response = await service.cadastrar(endereco);

            if(response !== undefined)
            {
                history.push('/confirmar');
            }

        } catch (error) {
            console.log(error)
        }
    }

    return (<>
        <div className="grid m-0">
            <div className="col-12 md:col-12 lg:col-8 lg:col-offset-2 xl:col-6 xl:col-offset-3 p-0">
                <div className="grid padding-content">
                    <div className="col-12 pt-4">
                        <div className="flex">
                            <div className="flex-initial flex align-items-center justify-content-center">
                                <i className="pi pi-angle-left button-back" onClick={() => history.push('/confirmar')}></i>
                            </div>
                            <div className="flex-1 flex align-items-center justify-content-center pr-3">
                                <span className='text-title-checkout font-semibold'>Novo endereço</span>
                            </div>
                            <div className="flex flex align-items-center"></div>
                        </div>
                    </div>
                    <Divider />
                    <div className="col-12 pl-3">
                        {
                            bairros ? <EnderecoForm bairros={bairros} onSalvar={salvar} /> : ''
                        }
                    </div>
                </div>
            </div>
        </div>
    </>);
}

export default EnderecoComponent;