import React from 'react';
import { Carrinho } from '../models/Carrinho';

const contextDefault = {
    carrinho: new Carrinho(),
    setCarrinho: (state: Carrinho) => {},
    // logado: GlobalService.isAutenticado(),
    // setLogado: (state: boolean) => {},
    // evento: new Evento(),
    // setEvento: (state: Evento) => {},
    // logout: () => {}
};
 
const GlobalContext = React.createContext(contextDefault);

export default GlobalContext;