import React from 'react';
import LayoutComponent from '../components/layout/LayoutComponent';
import EnderecoComponent from '../components/endereco/EnderecoComponent';

const CarrinhoContainer: React.FC = () => {
    
	const conteudo =
		<React.Fragment>
			<EnderecoComponent />
		</React.Fragment>

	return (
		<>
			<LayoutComponent content={conteudo} />
		</>
	);
}

export default CarrinhoContainer;