import { Bairro } from "./Bairro";

export class Endereco {

    constructor() {
        this.cep = '';
        this.logradouro = '';
        this.numero = '';
        this.complemento = '';
        this.cidade = '';
        this.estado = '';
        this.cep = '';
    }

    public id: number;
    public logradouro: string;
    public numero: string;
    public nomeBairro: string;
    public bairro: Bairro;
    public complemento: string;
    public cidade: string;
    public estado: string;
    public cep: string;
    public cliente: any;
    public valorEntrega: number;
}