import React, { useCallback, useContext, useEffect, useState } from 'react';import { ProdutoService } from '../../services/ProdutoService';
import { TamanhoCategoriaService } from '../../services/TamanhoCategoriaService';
import { Divider } from 'primereact/divider';
import { Button } from 'primereact/button';
import { InputTextarea } from 'primereact/inputtextarea';
import GlobalContext from '../../helpers/GlobalContext';
import { Checkbox } from 'primereact/checkbox';
import { RadioButton } from 'primereact/radiobutton';
import { UtilsHelper } from '../../helpers/UtilsHelper';
import toast, { Toaster } from 'react-hot-toast';

export interface IPizzaComponent {
    idTamanho?: any;
    onAdicionar: any;
}

const PizzaComponent: React.FC<IPizzaComponent> = (props) => {

    const { carrinho, setCarrinho } = useContext(GlobalContext);
    const [tamanhoCategoria, setTamanhoCategoria] = useState<any>();
    const [produtos, setProdutos] = useState<any[]>();
    const [observacao, setObservacao] = useState<string>();
    const [quantidade, setQuantidade] = useState<number>(1);
    const [total, setTotal] = useState(0);
    

    const carregaTamanhoCategoria = useCallback(async () => {
        try {
            if (props.idTamanho) {
                let service = new TamanhoCategoriaService();
                let response = await service.obter(parseInt(props.idTamanho));

                if (response !== undefined) {
                    setTamanhoCategoria(response);
                }
            }
        } catch (error) {
            console.log(error)
        }
    }, [props.idTamanho])

    const carregaProdutos = useCallback(async () => {

        try {
            if (props.idTamanho) {
                let service = new ProdutoService();
                let response = await service.obterPorTamanhoCategoria(parseInt(props.idTamanho));

                if (response !== undefined) {
                    setProdutos(response);
                }
            }
        }
        catch (error) {
            console.log(error);
        }

    }, [props.idTamanho]);


    useEffect(() => {
        carregaProdutos();
        carregaTamanhoCategoria();
    }, [carregaProdutos, carregaTamanhoCategoria])


    const selecionarOpcao = (produtoSelecionado: any) => {

        let novoTotal = 0;
        let novosProdutos = [...produtos!];
        let item = novosProdutos?.filter(x => x.id === produtoSelecionado.id)[0]

        if (tamanhoCategoria.qtdSabores === 1) {
            let itemExistente = novosProdutos.filter(x => x.selecionado === true)[0];
            
            if(itemExistente)
            {
                itemExistente.selecionado = false;
            }

            item.selecionado = true;
        }
        
        if (tamanhoCategoria.qtdSabores > 1) {

            if (!item.selecionado && novosProdutos.filter(x => x.selecionado === true).length === tamanhoCategoria.qtdSabores) {
                toast.error(`Limite de sabores atingido`);
                return 0;
            }

            if (novosProdutos.filter(x => x.selecionado === true).length <= tamanhoCategoria.qtdSabores - 1) {
                item.selecionado = item.selecionado !== undefined ? !item.selecionado : true;
            } else {
                item.selecionado = false
            }
        }

        
        novoTotal = Math.max(...novosProdutos.filter(x => x.selecionado === true).map(p => p.produtoPrecos[0].preco)) > 0 ?
        Math.max(...novosProdutos.filter(x => x.selecionado === true).map(p => p.produtoPrecos[0].preco)) : 0;

        setProdutos(novosProdutos);
        setTotal(novoTotal);
    }

    const adicionarQuantidade = () => {
        setQuantidade(quantidade + 1);
    }

    const removerQuantidade = () => {
        if (quantidade > 1)
            setQuantidade(quantidade - 1);
    }

    const validarSabores = () => {
        
        if(tamanhoCategoria.qtdSabores !== produtos?.filter(x => x.selecionado === true).length)
        {
            toast(`Selecione ${tamanhoCategoria.qtdSabores} ${tamanhoCategoria.qtdSabores > 1 ? 'sabores' : 'sabor'}`);
            return false;
        }

        return true;
    }

    const adicionarProduto = () => {

        let resultado = validarSabores();

        if(resultado)
        {
            let item = {
                produtos: produtos?.filter(x => x.selecionado === true),
                tamanhoCategoria: tamanhoCategoria,
                observacao: observacao,
                quantidade: quantidade
            }
    
            carrinho.itens.push(item);
            setCarrinho(carrinho)
            UtilsHelper.setLocalStorage('cart.delivery', carrinho);
            props.onAdicionar();
        }        
    }

    return (
        <>
            <Toaster position="bottom-center" reverseOrder={false} />
            {
                tamanhoCategoria && produtos ?
                    <div className="grid m-0">
                        <div className="col-12 mt-3">
                            <div className='product-item-name'>
                                {tamanhoCategoria.nome}
                            </div>
                            <div className='product-item-desc'>{tamanhoCategoria.nome} {tamanhoCategoria.qtdSabores > 1 ? `${tamanhoCategoria.qtdSabores} sabores` : ''} ({tamanhoCategoria.qtdPedacos} pedaços)</div>
                        </div>
                        <div className="col-12 complement-title">
                            <Divider />
                            escolha {tamanhoCategoria.qtdSabores} {tamanhoCategoria.qtdSabores > 1 ? 'sabores' : 'sabor'}
                        </div>
                        {
                            produtos.map((produto, i) => (
                                <div className="col-12" key={i}>
                                    <div className="flex flex-wrap gap-3">
                                        <div className="flex-1 align-items-center complement-name">
                                            {
                                                tamanhoCategoria.qtdSabores === 1 ?
                                                <RadioButton inputId={produto.nome} value={produto.id} onChange={(e) => selecionarOpcao(produto)} checked={produto.selecionado ? produto.selecionado : false} />
                                                : <Checkbox onChange={e => selecionarOpcao(produto)} checked={produto.selecionado ? produto.selecionado : false} />
                                            }
                                            <label htmlFor={produto.nome} className="ml-2 ">{produto.nome}</label>
                                        </div>
                                        <div className='flex product-price'>
                                            R$ {UtilsHelper.formatCurrency(produto.produtoPrecos[0].preco)}
                                        </div>
                                    </div>
                                </div>
                            ))
                        }
                        <Divider />
                        <div className="col-12">
                            <div className="flex flex-column gap-2">
                                <label className='label'>Alguma observação?</label>
                                <InputTextarea name='obsrvacao' rows={2} placeholder='Tirar a cebola, maionese à parte, ponto da carne etc' value={observacao} onChange={(e) => setObservacao(e.target.value)} />
                            </div>
                        </div>
                        <Divider />
                        <div className="col-12 product-total">
                            <span>valor unitário: <span className='value'>R$ {UtilsHelper.formatCurrency(total)}</span></span>
                        </div>
                        <div className="col-12 mb-4">
                            <div className="flex-1 flex text-center">
                                <div className="flex-1 flex align-items-center justify-content-center">
                                    <Button className='button-primary-icon' icon="pi pi-minus" rounded text aria-label="Cancel" size='small' onClick={removerQuantidade} />
                                </div>
                                <div className="flex-1 flex align-items-center justify-content-center">
                                    {quantidade}
                                </div>
                                <div className="flex-1 flex align-items-center justify-content-center">
                                    <Button className='button-primary-icon' icon="pi pi-plus" rounded text aria-label="Cancel" size='small' onClick={adicionarQuantidade} />
                                </div>
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="flex">
                                <div className="flex-1 flex align-items-center justify-content-center px-2">
                                <Button className='button-block button-primary' label={`Adicionar - R$ ${UtilsHelper.formatCurrency(total * quantidade)}`} onClick={adicionarProduto} />
                                </div>
                            </div>
                        </div>
                    </div>
                    : ''
            }
        </>
    );
}

export default PizzaComponent;