import api from "./Api";
import { ResponseHelper } from "../helpers/ResponseHelper";
import { Carrinho } from "../models/Carrinho";

export class PedidoService {

    private endpoint = 'v1/pedidos';

    public async cadastrar(carrinho: Carrinho): Promise<any> {
        return await api.post<any>(`${this.endpoint}`, carrinho)
            .then((response) => (ResponseHelper.sucesso(response)))
            .catch((error) => (Promise.reject(ResponseHelper.erro(error))));
    }
}