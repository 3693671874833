import * as Yup from "yup";
import { ErrorMessage, Form, FormikProps, withFormik } from 'formik';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { Endereco } from "../../models/Endereco";
import { EnderecoService } from "../../services/EnderecoService";
import { Bairro } from "../../models/Bairro";

export interface IEnderecoForm {
    bairros: Bairro[];
    onSalvar: any;
}

const innerForm = (props: IEnderecoForm & FormikProps<Endereco>) => {
    const { values } = props;

    const bairros = props.bairros.map((bairro: Bairro) => ({
        key: bairro.id,
        text: bairro.nome,
        id: bairro
    }));

    const onBlurCep = async (event: any) => {
        const { value } = event.target;

        try {
            if(value.length > 7)
            {
                let service = new EnderecoService();
                let response = await service.consultarCep(value.replace("-", ""));

                if(response.logradouro !== undefined)
                {
                    props.setFieldValue(`logradouro`, response.logradouro);
                    props.setFieldValue(`bairro`, bairros.filter(x => x.text.includes(response.bairro))[0].id);
                    props.setFieldValue(`cidade`, response.localidade);
                    props.setFieldValue(`estado`, response.uf);
                }
            }
        }
        catch (error) {
            console.log(error);
        }
    }

    return (
        <Form>
            <div className="grid w-12 m-0">
                <div className="col-12">
                    <div className="label">CEP</div>
                    <InputText name="cep" className="w-full" value={values.cep} onChange={props.handleChange} onBlur={onBlurCep} />
                    <small className="invalid"><ErrorMessage name="cep" /></small>
                </div>
                <div className="col-12">
                    <div className="label">Rua</div>
                    <InputText name="logradouro" className="w-full" value={values.logradouro} onChange={props.handleChange} />
                    <small className="invalid"><ErrorMessage name="logradouro" /></small>
                </div>
                <div className="col-12">
                    <div className="label">Número</div>
                    <InputText name="numero" className="w-full" value={values.numero} onChange={props.handleChange} />
                    <small className="invalid"><ErrorMessage name="numero" /></small>
                </div>
                <div className="col-12">
                    <div className="label">Bairro</div>
                    <Dropdown
                        name="bairro"
                        className="w-full"
                        optionLabel="text"
                        optionValue="id"
                        filter
                        value={values.bairro}
                        options={bairros}
                        onChange={props.handleChange}
                        placeholder="Selecione o bairro"
                    />
                    <small className="invalid"><ErrorMessage name="bairro" /></small>
                </div>
                <div className="col-12">
                    <div className="label">Complemento</div>
                    <InputText name="complemento" className="w-full" value={values.complemento} onChange={props.handleChange} />
                </div>
                <div className="col-12">
                    <Button className='p-button-primary w-full' severity="danger" type="submit" label="Salvar" disabled={props.isSubmitting} loading={props.isSubmitting} />
                </div>
            </div>
        </Form>
    );
};

const validationForm = Yup.object().shape({
    logradouro: Yup.string().required("Campo obrigatório")
    .min(5, 'Preencha a rua o completa'),
    numero: Yup.string().required("Campo obrigatório"),
    bairro: Yup.object().required("Campo obrigatório")
});


const EnderecoForm = withFormik<IEnderecoForm, Endereco>({
    
    mapPropsToValues: (props) => {
        return new Endereco();
    },

    validationSchema: validationForm,

    handleSubmit: async (values, { props }) => {
        await props.onSalvar(values);
    }
})(innerForm);

export default EnderecoForm;