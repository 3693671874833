import React, { useState } from 'react';
import GlobalContext from './GlobalContext';
import { Carrinho } from '../models/Carrinho';

const ProvideContext = ({children}: any) => {
	
	const [carrinho, setCarrinho] = useState(new Carrinho());
	
	return (
		<GlobalContext.Provider value={{carrinho, setCarrinho}}>
			{children}
		</GlobalContext.Provider>
	);
}

export default ProvideContext;