import React from 'react';
import LayoutComponent from '../components/layout/LayoutComponent';
import CarrinhoComponent from '../components/checkout/CarrinhoComponent';

const CarrinhoContainer: React.FC = () => {
    
	const conteudo =
		<React.Fragment>
			<CarrinhoComponent />
		</React.Fragment>

	return (
		<>
			<LayoutComponent content={conteudo} />
		</>
	);
}

export default CarrinhoContainer;