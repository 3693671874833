import React from 'react';
import './style.css';

const OnboardingComponent: React.FC = () => {
    
    document.title = 'Cardápio digital para delivery grátis — zeeshop';

    return (<iframe src="https://app.pipefy.com/public/form/_WxV2l0m" className='form' frameBorder="none"></iframe>);
}

export default OnboardingComponent;