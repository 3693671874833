import { ResponseHelper } from "../helpers/ResponseHelper";
import { Estabelecimento } from "../models/Estabelecimento";
import api from "./Api";

export class EstabelecimentoService {
    
    private endpoint = 'v1/estabelecimentos';

    public async obterPorUrl(url: string): Promise<Estabelecimento> {
        return await api.get<Estabelecimento>(`${this.endpoint}/url/${url}`)
            .then((response) => (ResponseHelper.sucesso(response)))
            .catch((error) => (Promise.reject(ResponseHelper.erro(error))));
    }
}