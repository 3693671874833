import { Divider } from 'primereact/divider';
import React, { useEffect, useState } from 'react';
import CadastroForm from './CadastroForm';
import { useHistory } from 'react-router-dom';
import { UtilsHelper } from '../../helpers/UtilsHelper';
import { ClienteService } from '../../services/ClienteService';

const IdentificacaoComponent: React.FC = () => {

    const history = useHistory();
    const [dadosCliente, setDadosCliente] = useState<any>();

    const salvarDados = async (cliente: any) => {
        try {

            let service = new ClienteService();
            let response = await service.cadastrar(cliente);

            if (response !== undefined)
            {
                UtilsHelper.setLocalStorage('client.delivery', response);
            }

            history.push('/confirmar');
                
        } catch (error) {
            console.log(error);
        }
    }
    
    const obterCliente = () => {

        let dados = UtilsHelper.getLocalStorage('client.delivery');
        
        if(dados !== undefined || dados !== null)
        {
            setDadosCliente(dados);
        }
    }

    useEffect(() => {
        obterCliente();
    }, [])
    
    return (<>
        <div className="grid m-0">
            <div className="col-12 md:col-12 lg:col-8 lg:col-offset-2 xl:col-6 xl:col-offset-3 p-0">
                <div className="grid padding-content">
                    <div className="col-12 pt-4">
                        <div className="flex">
                            <div className="flex-initial flex align-items-center justify-content-center">
                                
                            </div>
                            <div className="flex-1 flex align-items-center justify-content-center pr-3">
                                <span className='text-title-checkout font-semibold'>Identifique-se</span>
                            </div>
                            <div className="flex flex align-items-center"></div>
                        </div>
                    </div>
                    <Divider />
                    <div className="col-12 pl-3">
                        <CadastroForm cliente={dadosCliente} onLogin={salvarDados} />
                    </div>
                </div>
            </div>
        </div>
    </>);
}

export default IdentificacaoComponent;