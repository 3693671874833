import { Button } from 'primereact/button';
import { Divider } from 'primereact/divider';
import React, { useContext, useEffect } from 'react';
import GlobalContext from '../../helpers/GlobalContext';
import { useHistory } from 'react-router-dom';
import { UtilsHelper } from '../../helpers/UtilsHelper';


const CarrinhoComponent: React.FC = () => {

    const history = useHistory();
    const { carrinho, setCarrinho } = useContext(GlobalContext);

    const calcularTotal = () => {
        
        let totalProdutos = carrinho.itens.reduce((a, b) => {
            if (b.tamanhoCategoria === undefined) {
                return a + b.produto.produtoPrecos[0].preco * b.quantidade;
            } else {
                return a;
            }
        }, 0);

        let totalPizza = carrinho.itens.reduce((a, b) => {
            if (b.tamanhoCategoria !== undefined) {
                
                return a + (Math.max(...b.produtos.map(p => p.produtoPrecos[0].preco))) * b.quantidade
            } else {
                return a;
            }
        }, 0);

        
        let totalComplementos = carrinho.itens.reduce((a, b) => {
            if(b.produto && b.produto.grupoComplementos)
            {
                return b.quantidade * (a + b.produto.grupoComplementos.reduce((x, y) =>  x + (y.complementos.reduce((ac, c) => ac + (c.selecionado === true ? c.preco : 0), 0)), 0))
            } else {
                return a;
            }
        }, 0);

        carrinho.totalProdutos = totalProdutos + totalPizza + totalComplementos;
        setCarrinho({...carrinho})
    }

    useEffect(() => {
        calcularTotal();
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const verificarCarrinho = () => {
        
        // if(carrinho.estabelecimento)
        // {
        //     UtilsHelper.setLocalStorage('cart.delivery', carrinho);
        // }

        if(carrinho.estabelecimento === undefined)
        {
            let dadosCarrinho = UtilsHelper.getLocalStorage('cart.delivery');
            setCarrinho({...dadosCarrinho});
        }

        if(carrinho.estabelecimento && carrinho.itens.length === 0)
        {
            history.push(`/${carrinho.estabelecimento.url}`)
        }
    }

    useEffect(() => {
        verificarCarrinho();
    }, [carrinho]) // eslint-disable-line react-hooks/exhaustive-deps

    const adicionarQuantidade = (indice: number) => {
        let item = carrinho.itens[indice];

        item.quantidade = item.quantidade + 1;

        setCarrinho({...carrinho})
        calcularTotal();
        UtilsHelper.setLocalStorage('cart.delivery', carrinho);
    }

    const removerQuantidade = (indice: number) => {
        let item = carrinho.itens[indice];
        if(item.quantidade > 1)
        {
            item.quantidade = item.quantidade - 1;
        }else {
            carrinho.itens.splice(indice, 1);
        }

        setCarrinho({...carrinho})
        calcularTotal();
        UtilsHelper.setLocalStorage('cart.delivery', carrinho);
    }

    return (
        <>
            {
                carrinho ?
                    <div className="grid m-0">
                        <div className="col-12 md:col-12 lg:col-8 lg:col-offset-2 xl:col-6 xl:col-offset-3 p-0">
                            <div className="grid padding-content">
                                <div className="col-12 pt-4">
                                    <div className="flex">
                                        <div className="flex-initial flex align-items-center justify-content-center">
                                            <i className="pi pi-angle-left button-back" onClick={() => history.push(carrinho.estabelecimento.url)}></i>
                                        </div>
                                        <div className="flex-1 flex align-items-center justify-content-center pr-3">
                                            <span className='text-title-checkout font-semibold'>Carrinho</span>
                                        </div>
                                        <div className="flex flex align-items-center"></div>
                                    </div>
                                </div>
                                <Divider />
                                {
                                    carrinho.itens.map((item, i) => (
                                        <div className="col-12 px-3" key={i}>
                                            <div className="grid">
                                                <div className="col-9">
                                                    <div className='text-item-name-cart'>
                                                        {item.tamanhoCategoria !== undefined ? `${item.tamanhoCategoria.nome} ${item.tamanhoCategoria.qtdSabores} ${item.tamanhoCategoria.qtdSabores > 1 ? 'Sabores' : 'Sabor'} (${item.tamanhoCategoria.qtdPedacos} Pedaços)` : item.produto.nome}
                                                    </div>
                                                    {
                                                        item.tamanhoCategoria ?
                                                            <div className='mt-1'>
                                                                {
                                                                    item.produtos.map((produto, j) => (
                                                                        <div className='text-sabor-pizza' key={j}>
                                                                            1/{item.produtos.length} - {produto.nome}
                                                                        </div>
                                                                    ))
                                                                }
                                                            </div> : ''
                                                    }
                                                    {
                                                        item.produto && item.produto.grupoComplementos !== undefined ?
                                                            <div className='mt-1'>
                                                                {
                                                                    item.produto.grupoComplementos.map((grupo, i) => (
                                                                        <div key={i}>
                                                                            {
                                                                                grupo.complementos.map((complemento, j) => (
                                                                                    <div key={j}>
                                                                                        {
                                                                                            complemento.selecionado ?
                                                                                            <div className='flex text-sabor-pizza'>
                                                                                                <div className="flex-1">{complemento.nome}</div>
                                                                                                <div className="flex">
                                                                                                    {
                                                                                                        complemento.preco > 0 ?
                                                                                                        `R$ ${UtilsHelper.formatCurrency(complemento.preco)}` : ''
                                                                                                    }
                                                                                                </div>
                                                                                            </div>
                                                                                            : ''
                                                                                        }
                                                                                    </div>
                                                                                ))
                                                                            }
                                                                        </div>
                                                                    ))
                                                                }
                                                            </div> : ''
                                                    }
                                                    <div className='text-price-cart mt-1'>
                                                        {
                                                            item.tamanhoCategoria !== undefined ? `R$ ${UtilsHelper.formatCurrency(Math.max(...item.produtos.map(p => p.produtoPrecos[0].preco)))}`
                                                                : `R$ ${UtilsHelper.formatCurrency(item.produto.produtoPrecos[0].preco + (item.produto.grupoComplementos ? 
                                                                    item.produto.grupoComplementos.reduce((a, b) => a + b.complementos.reduce((x, y) => x + (y.selecionado === true ? y.preco : 0), 0), 0) : 0))}`
                                                        }
                                                    </div>
                                                    {
                                                        item.observacao ? <div className='text-info'>Obs: {item.observacao}</div> : ''
                                                    }
                                                </div>
                                                <div className="col-3">
                                                    <div className="flex mr-5">
                                                        <div className="flex-1 flex align-items-center justify-content-end">
                                                            <Button className='button-primary-icon' icon={item.quantidade > 1 ? 'pi pi-minus' : 'pi pi-trash'} rounded text aria-label="Cancel" size='small' onClick={() => removerQuantidade(i)} />
                                                        </div>
                                                        <div className="flex flex align-items-center justify-content-end">
                                                            {item.quantidade}
                                                        </div>
                                                        <div className="flex flex align-items-center justify-content-end">
                                                            <Button className='button-primary-icon' icon="pi pi-plus" rounded text aria-label="Cancel" size='small' onClick={() => adicionarQuantidade(i)} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                }
                                <Divider />
                                <div className="col-12 pl-3">
                                    <div>
                                        <div className='text-label-total'>
                                            Total de produtos
                                        </div>
                                        <div className='text-value-total'>
                                            R$ {UtilsHelper.formatCurrency(carrinho.totalProdutos)}
                                        </div>
                                    </div>
                                </div>
                                <Divider />
                                <div className="col-12 pl-3">
                                    <Button className="button-block py-3 font-semibold button-primary" disabled={carrinho.totalProdutos < carrinho.estabelecimento?.pedidoMinimo} onClick={() => history.push("/confirmar")}>
                                        
                                        {
                                            carrinho.totalProdutos < carrinho.estabelecimento?.pedidoMinimo ?
                                            <>
                                                <span className="mr-auto">Pedido mínimo</span>
                                                <span className="ml-auto">R$ {UtilsHelper.formatCurrency(carrinho.estabelecimento?.pedidoMinimo)}</span>
                                            </>
                                            :
                                            <>
                                                <span className="mr-auto">Avançar</span>
                                                <span className="ml-auto">R$ {UtilsHelper.formatCurrency(carrinho.totalProdutos)}</span>
                                            </>
                                        }
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                    : ''
            }
        </>
    );
}

export default CarrinhoComponent;