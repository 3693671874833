import React, { useContext, useEffect, useState } from 'react';
import './style.css';
import { UtilsHelper } from '../../helpers/UtilsHelper';
import { EstabelecimentoService } from '../../services/EstabelecimentoService';
import { Estabelecimento } from '../../models/Estabelecimento';
import { CategoriaService } from '../../services/CategoriaService';
import { useHistory } from 'react-router-dom';
import GlobalContext from '../../helpers/GlobalContext';
import { Dialog } from 'primereact/dialog';
import ProdutoComponent from '../produto/ProdutoComponent';
import PizzaComponent from '../pizza/PizzaComponent';
import PerfilGenerico from '../../content/img/perfil-generico.png'
import CapaGenerica from '../../content/img/capa-generica.png'
import { ClienteService } from '../../services/ClienteService';
import toast, { Toaster } from 'react-hot-toast';
import { Carrinho } from '../../models/Carrinho';

export interface ILojaComponent {
    urlEstabelecimento: string;
    chave: any;
}

const LojaComponent: React.FC<ILojaComponent> = (props) => {

    const history = useHistory();
    const { carrinho, setCarrinho } = useContext(GlobalContext);
    const [openPanels, setOpenPanels] = useState<number[]>([]);
    const [estabelecimento, setEstabelecimento] = useState<Estabelecimento>();
    const [categorias, setCategorias] = useState<any[]>();
    const [showInfos, setShowInfos] = useState(false);

    const [produto, setProduto] = useState<any>();
    const [showProduto, setShowProduto] = useState(false);
    
    const [tamanho, setTamanho] = useState<any>();
    const [showPizza, setShowPizza] = useState(false);

    const togglePanel = (index: number) => {
        if(estabelecimento?.aberto)
        {
            if (openPanels.includes(index)) {
                setOpenPanels(openPanels.filter((item) => item !== index));
            } else {
                setOpenPanels([...openPanels, index]);
            }
        }else {
            toast(`A loja está fechada`);
        }
    };

    const carregaCategorias = async (idEstabelecimento: number) => {

        try {

            let service = new CategoriaService();
            let response = await service.obterPorEstabelecimento(idEstabelecimento);

            if (response !== undefined)
                setCategorias(response);
        }
        catch (error) {
            console.log(error);
        }
    };

    const carregaCliente = async () => {

        if(props.chave !== undefined)
        {
            let service = new ClienteService();
            let response = await service.obterPorChave(props.chave);

            if(response !== undefined)
            {
                UtilsHelper.setLocalStorage('client.delivery', response);
            }
        }
    }

    const carregaEstabelecimento = async () => {
        
        try {
            let service = new EstabelecimentoService();
            let response = await service.obterPorUrl(props.urlEstabelecimento);

            if (response !== undefined) {
                setEstabelecimento(response);
                carregaCategorias(response.id);
                document.title = response.nomeFantasia;

                if(carrinho.estabelecimento === undefined)
                {
                    let dadosCarrinho = UtilsHelper.getLocalStorage('cart.delivery');

                    if((dadosCarrinho === null) || (dadosCarrinho !== null && dadosCarrinho.estabelecimento.id !== response.id))
                    {   
                        let novoCarrinho = new Carrinho();
                        novoCarrinho.estabelecimento = response;
                        
                        setCarrinho(novoCarrinho);
                        
                        UtilsHelper.setLocalStorage('cart.delivery', novoCarrinho)
                    }
                    
                    if(dadosCarrinho !== null && dadosCarrinho.estabelecimento.id === response.id)
                    {
                        dadosCarrinho.estabelecimento = response;
                        setCarrinho({...dadosCarrinho});
                        UtilsHelper.setLocalStorage('cart.delivery', dadosCarrinho)
                    }
                }

                await carregaCliente();
            }
        }
        catch (error) {
            console.log(error);
        }

    };

    useEffect(() => {
        carregaEstabelecimento();
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const calcularTotal = () => {
        
        let totalProdutos = carrinho.itens.reduce((a, b) => {
            if (b.tamanhoCategoria === undefined) {
                return a + b.produto.produtoPrecos[0].preco * b.quantidade;
            } else {
                return a;
            }
        }, 0);

        let totalPizza = carrinho.itens.reduce((a, b) => {
            if (b.tamanhoCategoria !== undefined) {
                return a + (Math.max(...b.produtos.map(p => p.produtoPrecos[0].preco))) * b.quantidade
            } else {
                return a;
            }
        }, 0);

        
        let totalComplementos = carrinho.itens.reduce((a, b) => {
            if(b.produto && b.produto.grupoComplementos)
            {
                return b.quantidade * (a + b.produto.grupoComplementos.reduce((x, y) =>  x + (y.complementos.reduce((ac, c) => ac + (c.selecionado === true ? c.preco : 0), 0)), 0))
            } else {
                return a;
            }
        }, 0);

        carrinho.totalProdutos = totalProdutos + totalPizza + totalComplementos;
        setCarrinho({...carrinho})
    }

    useEffect(() => {
        calcularTotal();
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const abrirProduto = (produtoSelecionado: any) => {
        setProduto(produtoSelecionado);
        setShowProduto(true);
    }

    const abrirPizza = (tamanhoSelecionado: any) => {
        setTamanho(tamanhoSelecionado);
        setShowPizza(true);
    }

    const fecharProduto = () => {
        calcularTotal();
        setShowProduto(false);
    }

    const fecharPizza = () => {
        calcularTotal();
        setShowPizza(false);
    }
    
    return (
        <>
            <Toaster position="bottom-center" reverseOrder={false} />
            {
                estabelecimento ?
                    <div className="grid m-0">
                        <div className="col-12 md:col-12 lg:col-8 lg:col-offset-2 xl:col-6 xl:col-offset-3 p-0">
                            <div className="grid store-cover m-0" style={{ backgroundImage: `url(${(estabelecimento.capa) ? `https://zeeshop-assets.s3.amazonaws.com/estabelecimento/${estabelecimento.capa}` : CapaGenerica})` }}>
                                <div className="col-8 sm:col-9 md:col-9 lg:col-9"></div>
                                <div className="col-4 sm:col-3 md:col-3 lg:col-3 pos-store-logo horizontal-padding-percent">
                                    <div className='store-logo' style={{ backgroundImage: `url(${(estabelecimento.perfil) ? `https://zeeshop-assets.s3.amazonaws.com/estabelecimento/${estabelecimento.perfil}` : PerfilGenerico})` }}></div>
                                    <div className='status-store'>
                                        {estabelecimento.aberto ? <span className='text-success'>loja aberta</span> : <span className='text-error'>loja fechada</span>}
                                    </div>
                                </div>
                            </div>
                            <div className='grid store-header m-0'>
                                <div className="col-12">
                                    <h1 className='store-name'>{estabelecimento.nomeFantasia}</h1>
                                </div>
                                <div className="col-4 store-header-info">
                                    <div className='value m-1'>{estabelecimento.tempoMedio}</div>
                                    <div className='label'>minutos</div>
                                </div>
                                <div className="col-4 store-header-info">
                                    <div className='value m-1'>
                                        R$ {UtilsHelper.formatCurrency(estabelecimento.pedidoMinimo)}
                                    </div>
                                    <div className='label'>mínimo</div>
                                </div>
                                {/* <div className="col-3 store-header-info">
                                    <div>
                                        <i className="pi pi-credit-card value m-1"></i>
                                    </div>
                                    <div className='label'>pagamento</div>
                                </div> */}
                                <div className="col-4 store-header-info" onClick={() => setShowInfos(true)}>
                                    <div>
                                        <i className="pi pi-info-circle value m-1"></i>
                                    </div>
                                    <div className='label'>infos</div>
                                </div>
                            </div>
                            <div className='grid store-categories m-0'>
                                {
                                    categorias && categorias.sort((a, b) => a.posicao > b.posicao ? 1 : -1)
                                    .map((categoria, index) => (
                                        <div className='categorie' key={index}>
                                            <div className="flex categorie-title" onClick={() => togglePanel(index)} key={index}>
                                                <div className="flex-1 flex">{categoria.nome}</div>
                                                <div className="flex icon">
                                                    {
                                                        openPanels.includes(index) ? <i className="pi pi-minus"></i> : <i className="pi pi-plus"></i>
                                                    }
                                                </div>
                                            </div>
                                            {openPanels.includes(index) &&
                                                <>
                                                    {
                                                        categoria.produtos && categoria.produtos.map((item, i) => (
                                                            <div className='flex categorie-content' key={i} onClick={() => abrirProduto(item)}> 
                                                                <div className="flex-1">
                                                                    <div className='product-name'>{item.nome}</div>
                                                                    {
                                                                        item.descricao ?
                                                                        <div className='product-desc'>{item.descricao}</div>
                                                                        : ''
                                                                    }
                                                                </div>
                                                                <div className="flex product-price">R$ {UtilsHelper.formatCurrency(item.produtoPrecos[0].preco)}</div>
                                                            </div>
                                                        ))
                                                    }

                                                    {
                                                        categoria.tamanhosCategoria && categoria.tamanhosCategoria.map((item, i) => (
                                                            <div className='flex categorie-content' key={i} onClick={() => abrirPizza(item)}>
                                                                <div className="flex-1 product-name"> {item.nome} {item.qtdSabores > 1 ? `${item.qtdSabores} sabores` : ''} ({item.qtdPedacos} pedaços)</div>
                                                                <div className="flex product-price">R$ {UtilsHelper.formatCurrency(item.produtoPrecos[0].preco)}</div>
                                                            </div>
                                                        ))
                                                    }
                                                </>
                                            }
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    </div> : ''
            }
            
            {
                produto ?
                    <Dialog
                        header={produto.nome} headerStyle={{ textAlign: 'center', textTransform: 'uppercase' }}
                        visible={showProduto} onHide={() => setShowProduto(false)}
                        className='produto'
                    >
                        <ProdutoComponent idProduto={produto.id} onAdicionar={fecharProduto} />
                    </Dialog>
                    : ''
            }

            {
                tamanho ?
                    <Dialog
                        header={tamanho.nome} headerStyle={{ textAlign: 'center', textTransform: 'uppercase' }}
                        visible={showPizza} onHide={() => setShowPizza(false)}
                        style={{width: '80vw'}} breakpoints={{ '992px': '80vw', '641px': '90vw' }}
                        className='produto'
                    >
                        <PizzaComponent idTamanho={tamanho.id} onAdicionar={fecharPizza} />
                    </Dialog>
                    : ''
            }
            
            {
                carrinho.itens.length > 0 ? 
                <div className="sidebar-container">
                    <div className="flex justify-content-between white-space-nowrap sidebar-order pointer" onClick={() => history.push('/carrinho')}>
                        <div className='flex align-items-center justify-content-center ml-2'>
                            <i className="pi pi-bookmark mr-1"></i>
                            <strong className='mr-2'>{carrinho.itens.length}</strong>
                        </div>
                        <div className='flex align-items-center justify-content-center mx-5'>
                            VER PEDIDO
                        </div>
                        <div className='flex align-items-center justify-content-center mr-2'>
                            R$ {UtilsHelper.formatCurrency(carrinho.totalProdutos)}
                        </div>
                    </div>
                </div> : ''
            }
            {
                estabelecimento ? 
                    <Dialog
                        header="Informações" headerClassName='header-infos'
                        visible={showInfos} onHide={() => setShowInfos(false)}
                        className='produto'
                    >
                        <div className="grid modal-infos">
                            <div className="col-12">Telefone: {estabelecimento.telefone}</div>
                            <div className="col-12">Delivery: {estabelecimento.entrega ? 'Sim' : 'Não'}</div>
                            <div className="col-12">Retirada: {estabelecimento.retirada ? 'Sim' : 'Não'}</div>
                        </div>
                    </Dialog>
                    : ''
            }
        </>
    );
}

export default LojaComponent;