import React, { useCallback, useContext, useEffect, useState } from 'react';
import { UtilsHelper } from '../../helpers/UtilsHelper';
import { Button } from 'primereact/button';
import { Divider } from 'primereact/divider';
import { useHistory } from 'react-router-dom';
import GlobalContext from '../../helpers/GlobalContext';
import { ConfirmDialog } from 'primereact/confirmdialog';
import { EnderecoService } from '../../services/EnderecoService';
import { Endereco } from '../../models/Endereco';
import { InputNumber } from 'primereact/inputnumber';
import { PedidoService } from '../../services/PedidoService';
import { Carrinho } from '../../models/Carrinho';
import toast, { Toaster } from 'react-hot-toast';
import { EstabelecimentoService } from '../../services/EstabelecimentoService';
import { Estabelecimento } from '../../models/Estabelecimento';

const ConfirmacaoComponent: React.FC = () => {

    const history = useHistory();
    const { carrinho, setCarrinho } = useContext(GlobalContext);
    const [enderecos, setEnderecos] = useState<Endereco[]>();
    const [dialogDados, setDialogDados] = useState(false);
    const [dialogTroco, setDialogTroco] = useState(false);
    const [troco, setTroco] = useState();
    const [envio, setEnvio] = useState(false);
    const [estabelecimento, setEstabelecimento] = useState<Estabelecimento>();

    const carregaEstabelecimento = useCallback(async () => {
        
        try {
            let service = new EstabelecimentoService();
            let response = await service.obterPorUrl(carrinho.estabelecimento.url);

            if (response !== undefined) {
                setEstabelecimento(response);

                if(!response.aberto)
                {
                    toast(`A loja está fechada`);
                    let novoCarrinho = new Carrinho();
                    novoCarrinho.estabelecimento = carrinho.estabelecimento;
                    setCarrinho(novoCarrinho);
                    UtilsHelper.setLocalStorage('cart.delivery', novoCarrinho)
                    history.push(carrinho.estabelecimento.url);
                }
            }
        }
        catch (error) {
            console.log(error);
        }

    }, [carrinho, setCarrinho]);

    useEffect(() => {
        carregaEstabelecimento();
    }, [carregaEstabelecimento])

    const obterEnderecos = useCallback(async (idCliente: number, idEstabelecimento: number) => {
        try {
            let service = new EnderecoService();
            let response = await service.obterPorClienteEstabelecimento(idCliente, idEstabelecimento);

            if (response !== undefined) {
                setEnderecos(response);

                if (response.length === 1) {
                    carrinho.endereco = response[0];
                    setCarrinho({ ...carrinho })
                }
            }
        } catch (error) {
            console.log(error)
        }
    }, [carrinho, setCarrinho])

    const obterClienteCarrinho = useCallback(async () => {

        let dados = UtilsHelper.getLocalStorage('client.delivery');

        if(dados === undefined || dados === null)
        {
            history.push('/identificacao');
        }else{
            
            if (carrinho.estabelecimento === undefined) 
            {
                let dadosCarrinho = UtilsHelper.getLocalStorage('cart.delivery');
                setCarrinho({...dadosCarrinho});
                await obterEnderecos(dados.id, dadosCarrinho.estabelecimento.id);
            }else {
                carrinho.cliente = dados;
                setCarrinho({ ...carrinho })
                UtilsHelper.setLocalStorage('cart.delivery', carrinho);
                await obterEnderecos(dados.id, carrinho.estabelecimento.id);
            }

            setDialogDados(true);
        }
    }, [history, carrinho, obterEnderecos, setCarrinho])

    useEffect(() => {
        obterClienteCarrinho();
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const selecionarEndereco = (enderecoSelecionado: any) => {
        
        if(estabelecimento && estabelecimento.minimoFreteGratis)
        {
            if(carrinho.totalProdutos >= estabelecimento.minimoFreteGratis)
                enderecoSelecionado.valorEntrega = 0;
        }

        carrinho.endereco = enderecoSelecionado;
        setCarrinho({...carrinho})
        UtilsHelper.setLocalStorage('cart.delivery', carrinho);
    }

    const trocarEndereco = () => {
        carrinho.endereco = undefined;
        setCarrinho({...carrinho})
    }

    const selecionarMeioPagamento = (meioPagamentoSelecionado: string) => {
        carrinho.meioPagamento = meioPagamentoSelecionado;
        setCarrinho({...carrinho})
        
        if(meioPagamentoSelecionado === 'Dinheiro')
        {
            setDialogTroco(true);
        }else {
            carrinho.troco = undefined;
            setCarrinho({...carrinho})
        }

        UtilsHelper.setLocalStorage('cart.delivery', carrinho);
    }

    const trocarMeioPagamento = () => {
        carrinho.meioPagamento = undefined;
        setCarrinho({...carrinho})
    }

    const confirmarTroco = () => {
        carrinho.troco = troco;
        setCarrinho({...carrinho})
        setDialogTroco(false);
    }

    const numberChange = (event: any) => {
        setTroco(event.value)
    }

    const dadosTrocoContent = (
        <div className="flex flex-column">
            <div className="flex align-items-center text-confirm-troco mb-2">
                Seu pedido fechou em <span className='ml-1 font-bold'>R$ {UtilsHelper.formatCurrency(carrinho.totalProdutos + (carrinho.endereco !== undefined ? carrinho.endereco?.valorEntrega : 0))}</span>
            </div>
            <div className="flex align-items-center text-confirm-troco text-center mb-1 mt-3">
                Troco para:
            </div>
            <div className="flex align-items-center">
                <InputNumber name="troco" className='w-full' value={troco} onChange={numberChange} mode="currency" currency="BRL" locale="pt-BR" minFractionDigits={2} />
            </div>
        </div>
    );

    const dadosContent = (
        carrinho.cliente ?
        <div className="flex flex-column">
            <div className="flex align-items-center text-confirm-value mb-2">
                {carrinho.cliente.nome}
            </div>
            <div className="flex align-items-center text-confirm-value">
                {carrinho.cliente.telefone}
            </div>
        </div> : ''
    );

    const formasPagamento = [
        {nome: 'Pix'},
        {nome: 'Cartão'},
        {nome: 'Dinheiro'}
    ]

    const enviarPedido = async () => {
        try 
        {
            if(estabelecimento && carrinho.totalProdutos < estabelecimento?.pedidoMinimo)
            {
                toast(`Pedido mínimo de R$ ${UtilsHelper.formatCurrency(estabelecimento.pedidoMinimo)}`);
                return;
            }

            if(carrinho.endereco === undefined)
            {
                toast(`Informe o endereço de entrega`);
                return;
            }   

            if(carrinho.meioPagamento === undefined)
            {
                toast(`Selecione a forma de pagamento`);
                return;
            }

            let dados = carrinho;

            if(!dados.estabelecimento || !dados.cliente)
                dados = UtilsHelper.getLocalStorage('cart.delivery');

            setEnvio(true);
            let service = new PedidoService();
            let response = await service.cadastrar(carrinho);

            if (response !== undefined)
            {
                let novoCarrinho = new Carrinho();
                novoCarrinho.estabelecimento = carrinho.estabelecimento;
                setCarrinho(novoCarrinho);
                UtilsHelper.setLocalStorage('cart.delivery', novoCarrinho)
                history.push(carrinho.estabelecimento.url);
                window.location.href = response.linkWhatsApp;
            }
        }
        catch (error) {
            console.log(error);
        }finally{
            setEnvio(false);
        }
    }
    
    return (
        <>
            <Toaster position="bottom-center" reverseOrder={false} />
            {
                carrinho ?
                    <div className="grid m-0">
                        <div className="col-12 md:col-12 lg:col-8 lg:col-offset-2 xl:col-6 xl:col-offset-3 p-0">
                            <div className="grid padding-content">
                                <div className="col-12 pt-4">
                                    <div className="flex">
                                        <div className="flex-initial flex align-items-center justify-content-center">
                                            <i className="pi pi-angle-left button-back" onClick={() => history.push("carrinho")}></i>
                                        </div>
                                        <div className="flex-1 flex align-items-center justify-content-center pr-3">
                                            <span className='text-title-checkout font-semibold'>Finalizar pedido</span>
                                        </div>
                                        <div className="flex flex align-items-center"></div>
                                    </div>
                                </div>
                                <Divider />
                                <div className="col-12 pl-3">
                                    <div className='grid'>
                                        <div className="col-8">
                                            <div className='text-label-confirm'>
                                                Este pedido será entregue a:
                                            </div>
                                            <div className='text-value-name'>
                                                {carrinho.cliente ? carrinho.cliente.nome : ''}
                                            </div>
                                            <div className='text-value-tel'>
                                                {carrinho.cliente ? carrinho.cliente.telefone : ''}
                                            </div>
                                        </div>
                                        <div className="col-4 py-3 text-right pr-3">
                                            <Button className='button-primary-outlined' label="Trocar" size="small" outlined onClick={() => history.push('/identificacao')} />
                                        </div>
                                    </div>
                                </div>
                                <Divider />
                                <div className="col-12 pl-3">
                                    <div className='grid'>
                                        {
                                            carrinho.endereco === undefined ?
                                                <>
                                                    <div className="col-8">
                                                        <div className='text-label-confirm pt-2'>
                                                            Selecione o endereço de entrega:
                                                        </div>
                                                    </div>
                                                    <div className="col-4 text-right pr-3">
                                                        <Button className='button-primary-outlined' label="Adicionar" severity="danger" size="small" outlined onClick={() => history.push('/endereco')} />
                                                    </div>
                                                    <div className="col-12">
                                                        {
                                                            enderecos?.map((item, i) =>
                                                                <div className='py-3 address-option' key={i} onClick={() => selecionarEndereco(item)}>
                                                                    {item.logradouro}, {item.numero} - {item.nomeBairro}
                                                                </div>)
                                                        }
                                                        <Button className="button-block py-2 button-primary-outlined" label="Retirar no balcão" size="small" outlined onClick={() => selecionarEndereco({logradouro: 'Retirar no balcão', valorEntrega: 0})} />
                                                    </div>
                                                </>
                                                : ''
                                        }
                                        {
                                            carrinho.endereco ?
                                                <>
                                                    <div className='col-12 text-label-confirm'>
                                                        Endereço de entrega:
                                                    </div>
                                                    <div className="col-12">
                                                        <div className='py-3 address-option'>
                                                            <div className="grid align-items-center">
                                                                <div className="flex-1 align-items-center justify-content-center">
                                                                    {
                                                                        carrinho.endereco.numero ? 
                                                                        <>{carrinho.endereco.logradouro}, {carrinho.endereco.numero} - {carrinho.endereco.nomeBairro}</> : <>{carrinho.endereco.logradouro}</>
                                                                    }
                                                                    
                                                                </div>
                                                                <div className="flex px-2 pt-1">
                                                                    <Button className='button-primary-outlined' label="Trocar" size="small" outlined onClick={trocarEndereco} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                                : ''
                                        }
                                    </div>
                                </div>
                                <Divider />
                                <div className="col-12">
                                    <div className="grid">
                                        <div className='col-12 text-label-confirm'>
                                            Forma de pagamento:
                                        </div>
                                        <div className="col-12">
                                            {
                                                carrinho.meioPagamento ?
                                                    <div className='py-3 payment-option'>
                                                        <div className="grid align-items-center">
                                                            <div className="flex-1 align-items-center justify-content-center">
                                                                <div>
                                                                    {carrinho.meioPagamento}
                                                                </div>
                                                                {
                                                                    carrinho.meioPagamento === 'Dinheiro' && carrinho.troco ?
                                                                        <div className='info-troco'>Troco para R$ {UtilsHelper.formatCurrency(carrinho.troco)}</div> : ''
                                                                }
                                                            </div>
                                                            <div className="flex px-2 pt-1">
                                                                <Button label="Trocar" size="small" severity="danger" outlined onClick={trocarMeioPagamento} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    :
                                                    formasPagamento.map((item, i) =>
                                                        <div className='py-3 payment-option' key={i} onClick={() => selecionarMeioPagamento(item.nome)}>
                                                            {item.nome}
                                                        </div>)
                                            }
                                        </div>
                                    </div>
                                </div>
                                <Divider />
                                <div className="col-12 pl-3">
                                    <div className="flex justify-content-between flex-wrap text-values-confirm">
                                        <div className="flex align-items-center justify-content-center">Subtotal</div>
                                        <div className="flex align-items-center justify-content-center">R$ {UtilsHelper.formatCurrency(carrinho.totalProdutos)}</div>
                                    </div>
                                    <div className="flex justify-content-between flex-wrap text-values-confirm mt-3">
                                        <div className="flex align-items-center justify-content-center">Taxa de entrega</div>
                                        {
                                            estabelecimento && estabelecimento.minimoFreteGratis > 0 ?
                                                <div className="flex align-items-center justify-content-center">{carrinho.totalProdutos >= estabelecimento.minimoFreteGratis ? `R$ ${UtilsHelper.formatCurrency(0)}` : carrinho.endereco ? `R$ ${UtilsHelper.formatCurrency(carrinho.endereco.valorEntrega)}` : '-'}</div>
                                            :
                                            <div className="flex align-items-center justify-content-center">{carrinho.endereco ? `R$ ${UtilsHelper.formatCurrency(carrinho.endereco.valorEntrega)}` : '-'}</div>
                                        }
                                    </div>
                                    <div className="flex justify-content-between flex-wrap text-values-confirm-total mt-3">
                                        <div className="flex align-items-center justify-content-center">Total</div>
                                        <div className="flex align-items-center justify-content-center">R$ {UtilsHelper.formatCurrency(carrinho.totalProdutos + (carrinho.endereco !== undefined ? carrinho.endereco?.valorEntrega : 0))}</div>
                                    </div>
                                </div>
                                <Divider />
                                <div className="col-12 pl-3">
                                    <Button className="button-block py-3 font-semibold button-primary" disabled={envio} label='Fazer pedido' onClick={enviarPedido} />
                                </div>
                            </div>
                        </div>
                    </div>
                    : ''
            }
            <ConfirmDialog visible={dialogDados} onHide={() => { }}
                headerStyle={{ textAlign: 'center' }} closable={false}
                rejectLabel="Editar informações" acceptLabel='Confirmar'
                rejectClassName="button-primary-outlined"
                acceptClassName="button-primary"
                message={dadosContent} focusOnShow={false}
                header="Confirme seus dados" accept={() => setDialogDados(false)} reject={() => history.push('/identificacao')} />
            
            <ConfirmDialog visible={dialogTroco}
                headerStyle={{ textAlign: 'center' }} closable={false}
                rejectLabel="Não preciso" acceptLabel='Confirmar'
                rejectClassName="button-primary-outlined"
                acceptClassName="button-primary"
                message={dadosTrocoContent} focusOnShow={false}
                header="Precisa de troco?" accept={confirmarTroco} reject={() => setDialogTroco(false)} />
        </>
    );
}

export default ConfirmacaoComponent;