import React, {  } from 'react';

export interface ILayoutLojaComponent {
	content: React.ReactNode
}

const LayoutComponent: React.FC<ILayoutLojaComponent> = (props) => {

    return (
        <>
            {props.content}
        </>
    );
}

export default LayoutComponent;