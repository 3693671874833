import React from 'react';
import OnboardingComponent from '../components/Onboarding/OnboardingComponent';

const OnboardingContainer: React.FC = () => {

	return (
		<OnboardingComponent />
	);
}

export default OnboardingContainer;