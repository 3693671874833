import React from 'react';
import { useLocation, useParams } from 'react-router-dom';
import LayoutComponent from '../components/layout/LayoutComponent';
import LojaComponent from '../components/loja/LojaComponent';
import queryString from 'query-string';

const LojaContainer: React.FC = () => {
    
	const { search } = useLocation();
	const params = queryString.parse(search);
	const { urlEstabelecimento } = useParams<{ urlEstabelecimento: string }>();

	const conteudo =
		<React.Fragment>
			<LojaComponent urlEstabelecimento={urlEstabelecimento} chave={params.clientKey} />
		</React.Fragment>

	return (
		<>
			<LayoutComponent content={conteudo} />
		</>
	);
}

export default LojaContainer;