import React from 'react';
import HomeComponent from '../components/home/HomeComponent';

const HomeContainer: React.FC = () => {

	return (
		<HomeComponent />
	);
}

export default HomeContainer;