import { ResponseHelper } from "../helpers/ResponseHelper";
import api from "./Api";

export class CategoriaService {
    
    private endpoint = 'v1/categorias';

    public async obterPorEstabelecimento(id: number): Promise<any[]> {
        return await api.get<any[]>(`${this.endpoint}/estabelecimento/${id}`)
            .then((response) => (ResponseHelper.sucesso(response)))
            .catch((error) => (Promise.reject(ResponseHelper.erro(error))));
    }
}