import { Endereco } from "./Endereco";
import { Estabelecimento } from "./Estabelecimento";

export class Carrinho {

    
constructor() {
        this.itens = new Array<any>();
    }

    public estabelecimento: Estabelecimento;
    public cliente: any;
    public endereco: Endereco | undefined;
    public meioPagamento: string | undefined;
    public troco: number | undefined;
    public totalProdutos: number;
    public itens: any[];
}