import api from "./Api";
import { ResponseHelper } from "../helpers/ResponseHelper";
import { Endereco } from "../models/Endereco";
import { Cep } from "../models/Cep";

export class EnderecoService {

    private endpoint = 'v1/enderecos';

    public async consultarCep(cep: string): Promise<Cep> {
        return await api.get<string>(`${this.endpoint}/consulta_cep/${cep}`)
            .then((response) => (ResponseHelper.sucesso(response)))
            .catch((error) => (Promise.reject(ResponseHelper.erro(error))));
    }

    public async obterPorClienteEstabelecimento(idCliente: number, idEstabelecimento: number): Promise<Endereco[]> {
        return await api.get<Endereco[]>(`${this.endpoint}/cliente/${idCliente}/estabelecimento/${idEstabelecimento}`)
            .then((response) => (ResponseHelper.sucesso(response)))
            .catch((error) => (Promise.reject(ResponseHelper.erro(error))));
    }

    public async cadastrar(endereco: Endereco): Promise<Endereco> {
        return await api.post<Endereco>(`${this.endpoint}`, endereco)
            .then((response) => (ResponseHelper.sucesso(response)))
            .catch((error) => (Promise.reject(ResponseHelper.erro(error))));
    }

    public async atualizar(endereco: Endereco): Promise<Endereco> {
        return await api.put<Endereco>(`${this.endpoint}`, endereco)
            .then((response) => (ResponseHelper.sucesso(response)))
            .catch((error) => (Promise.reject(ResponseHelper.erro(error))));
    }
}