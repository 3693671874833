import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Button } from 'primereact/button';
import { Divider } from 'primereact/divider';
import { InputTextarea } from 'primereact/inputtextarea';
import { ProdutoService } from '../../services/ProdutoService';
import { UtilsHelper } from '../../helpers/UtilsHelper';
import GlobalContext from '../../helpers/GlobalContext';
import { RadioButton } from 'primereact/radiobutton';
import { Checkbox } from 'primereact/checkbox';
import toast, { Toaster } from 'react-hot-toast';


export interface IProdutoComponent {
    idProduto?: any;
    onAdicionar: any;
}

const ProdutoComponent: React.FC<IProdutoComponent> = (props) => {

    const { carrinho, setCarrinho } = useContext(GlobalContext);
    const [produto, setProduto] = useState<any>();
    const [observacao, setObservacao] = useState<string>();
    const [quantidade, setQuantidade] = useState<number>(1);
    const [total, setTotal] = useState(0);

    const carregaProduto = useCallback(async () => {

        try {
            if (props.idProduto) {
                let service = new ProdutoService();
                let response = await service.obter(parseInt(props.idProduto));

                if (response !== undefined) {
                    setProduto(response);
                    setTotal(response.produtoPrecos[0].preco);
                }
            }
        }
        catch (error) {
            console.log(error);
        }

    }, [props.idProduto]);

    useEffect(() => {
        carregaProduto();
    }, [carregaProduto])

    const selecionarOpcao = (grupo: any, itemSelecionado: any) => {
        let novoTotal = 0;
        let novoProduto = { ...produto };
        let item = grupo.complementos.filter(x => x.id === itemSelecionado.id)[0]
        
        if (grupo.qtdMaxima === 0) {
            item.selecionado = item.selecionado !== undefined ? !item.selecionado : true;

            if(item.selecionado === true)
            {
                novoTotal = total + item.preco;
            }else{
                novoTotal = total - item.preco;
            }
        }

        if (grupo.qtdMaxima === 1) {
            let itemExistente = grupo.complementos.filter(x => x.selecionado === true)[0];
            
            if(itemExistente)
            {
                itemExistente.selecionado = false;
                novoTotal = total - itemExistente.preco;
            }else {
                novoTotal = total;
            }
                
            item.selecionado = true;

            novoTotal = novoTotal + item.preco;
        }

        if (grupo.qtdMaxima > 1) {

            if (!item.selecionado && grupo.complementos.filter(x => x.selecionado === true).length === grupo.qtdMaxima) {
                toast(`Limite máximo atingido`);
                return 0;
            }

            if (grupo.complementos.filter(x => x.selecionado === true).length <= grupo.qtdMaxima - 1) {
                item.selecionado = item.selecionado !== undefined ? !item.selecionado : true;
                
                if(item.selecionado === true)
                {
                    novoTotal = total + item.preco;
                }else{
                    novoTotal = total - item.preco;
                }
                
            } else {
                item.selecionado = false
                novoTotal = total - item.preco;
            }
        }

        setProduto(novoProduto);
        setTotal(novoTotal);
    }

    const adicionarQuantidade = () => {
        setQuantidade(quantidade + 1);
    }

    const removerQuantidade = () => {
        if (quantidade > 1)
            setQuantidade(quantidade - 1);
    }

    const validarComplementos = () => {
        
        if(produto.grupoComplementos)
        {
            for (let index = 0; index < produto.grupoComplementos.length; index++) {
                const grupo = produto.grupoComplementos[index];
                
                if(grupo.qtdMinima >= 1 && grupo.complementos.filter(x => x.selecionado === true).length === 0)
                {
                    toast(`Selecione uma opção em ${grupo.nome}`);
                    return false;
                }
            }
        }
    
        return true;
    }

    const adicionarProduto = () => {
        
        let resultado = validarComplementos();

        if(resultado)
        {
            let item = {
                produto: produto,
                observacao: observacao,
                quantidade: quantidade
            }
    
            carrinho.itens.push(item);
            setCarrinho(carrinho)
            UtilsHelper.setLocalStorage('cart.delivery', carrinho);
            props.onAdicionar();
        }
    }

    return (
        <>
            <Toaster position="bottom-center" reverseOrder={false} />
            {
                produto ?
                    <div className="grid m-0">
                        <div className="col-12 mt-3">
                            <div className='product-item-name'>
                                {produto.nome}
                            </div>
                            {
                                produto.descricao ?
                                    <div className='product-item-desc'>{produto.descricao}</div>
                                    : ''
                            }
                            <div className='product-item-preco mt-3'>
                                <span>R$ {UtilsHelper.formatCurrency(produto.produtoPrecos[0].preco)}</span>
                            </div>
                        </div>
                        {
                            produto.grupoComplementos ?
                                <>
                                    {
                                        produto.grupoComplementos.map((grupo, i) => (
                                            <div className="col-12" key={i}>
                                                <Divider />
                                                <div className='complement-title'>
                                                    {grupo.nome} {grupo.qtdMaxima ? `(escolha de ${grupo.qtdMinima} a ${grupo.qtdMaxima})` : ''}
                                                </div>
                                                {
                                                    grupo.complementos.map((item, j) => (
                                                    <div className="my-3" key={j}>
                                                        <div className="flex flex-wrap gap-3">
                                                            <div className="flex-1 align-items-center complement-name">
                                                                {
                                                                    grupo.qtdMaxima === 1 ?
                                                                        <RadioButton inputId={item.nome} value={item.id} onChange={(e) => selecionarOpcao(grupo, item)} checked={item.selecionado ? item.selecionado : false} />
                                                                        : <Checkbox inputId={item.nome} onChange={e => selecionarOpcao(grupo, item)} checked={item.selecionado ? item.selecionado : false} />
                                                                }
                                                                <label htmlFor={item.nome} className="ml-2 ">{item.nome}</label>
                                                            </div>
                                                            <div className="flex align-items-center product-price">
                                                                {
                                                                    item.preco > 0 ?
                                                                        `+ R$ ${UtilsHelper.formatCurrency(item.preco)}` : ''
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))
                                            }
                                            </div>
                                        ))
                                    }
                                </>
                                : ''
                        }
                        <Divider />
                        <div className="col-12">
                            <div className="flex flex-column gap-2">
                                <label className='label'>Alguma observação?</label>
                                <InputTextarea name='obsrvacao' rows={2} placeholder='Tirar a cebola, maionese à parte, ponto da carne etc' value={observacao} onChange={(e) => setObservacao(e.target.value)} />
                            </div>
                        </div>
                        <Divider />
                        <div className="col-12 product-total">
                            <span>valor unitário: <span className='value'>R$ {UtilsHelper.formatCurrency(total)}</span></span>
                        </div>
                        <div className="col-12 mb-4">
                            <div className="flex-1 flex text-center">
                                <div className="flex-1 flex align-items-center justify-content-center">
                                    <Button className='button-primary-icon' icon="pi pi-minus" rounded text aria-label="Cancel" size='small' onClick={removerQuantidade} />
                                </div>
                                <div className="flex-1 flex align-items-center justify-content-center">
                                    {quantidade}
                                </div>
                                <div className="flex-1 flex align-items-center justify-content-center">
                                    <Button className='button-primary-icon' icon="pi pi-plus" rounded text aria-label="Cancel" size='small' onClick={adicionarQuantidade} />
                                </div>
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="flex">
                                
                                <div className="flex-1 flex align-items-center justify-content-center px-2">
                                    <Button className='button-block button-primary' label={`Adicionar - R$ ${UtilsHelper.formatCurrency(total * quantidade)}`} onClick={adicionarProduto} />
                                </div>
                            </div>
                        </div>
                    </div>
                    : ''
            }
        </>
    );
}

export default ProdutoComponent;