import { ResponseHelper } from "../helpers/ResponseHelper";
import api from "./Api";

export class TamanhoCategoriaService {
    
    private endpoint = 'v1/tamanho_categorias';

    public async obter(id: number): Promise<any> {
        return await api.get<any>(`${this.endpoint}/${id}`)
            .then((response) => (ResponseHelper.sucesso(response)))
            .catch((error) => (Promise.reject(ResponseHelper.erro(error))));
    }
}